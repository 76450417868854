// extracted by mini-css-extract-plugin
export var ColumbiaCareLogo = "location-module--ColumbiaCareLogo--4dadb";
export var EmailFormHeader = "location-module--EmailFormHeader--1baf5";
export var HeroButton = "location-module--HeroButton--a4e74";
export var InnerInfo = "location-module--InnerInfo--a3980";
export var LocationHero = "location-module--LocationHero--5c63b";
export var LocationHeroHeader = "location-module--LocationHeroHeader--0f60c";
export var LocationHeroInner = "location-module--LocationHeroInner--ba30a";
export var LocationImage = "location-module--LocationImage--910fe";
export var LocationText = "location-module--LocationText--e9e6e";
export var LoyaltyProgram = "location-module--LoyaltyProgram--3d797";
export var StoreAddress = "location-module--StoreAddress--10c3c";
export var StoreBody = "location-module--StoreBody--39d33";
export var StoreDetails = "location-module--StoreDetails--f00a7";
export var StoreDirections = "location-module--StoreDirections--8a74d";
export var StoreEmailSignup = "location-module--StoreEmailSignup--32221";
export var StoreInfo = "location-module--StoreInfo--7c0e2";
export var StoreName = "location-module--StoreName--d1a0c";
export var StoreOffers = "location-module--StoreOffers--e1e57";
export var YourLocation = "location-module--YourLocation--205e3";
export var YourLocationMobile = "location-module--YourLocationMobile--47e94";
export var aboutSection = "location-module--aboutSection--5b880";
export var aboutSectionContainer = "location-module--aboutSectionContainer--93707";
export var hero_button_container = "location-module--hero_button_container--b3df7";
export var store_info_area = "location-module--store_info_area--ddcc6";
export var store_info_section = "location-module--store_info_section--42d92";
export var tgs_text = "location-module--tgs_text--68505";