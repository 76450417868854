import React from "react"; // eslint-disable-line

const TgsStashCash = ({ color, className, height }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5393_80487)">
      <path
        d="M20.1005 14.8001V4.40007C20.1005 3.23313 19.637 2.1139 18.8118 1.28873C17.9867 0.463533 16.8675 0 15.7005 0H5.30046C4.13352 0 3.01429 0.463533 2.18912 1.28873C1.36392 2.11387 0.900391 3.23307 0.900391 4.40007V14.8001C0.900391 15.967 1.36392 17.0862 2.18912 17.9114C3.01426 18.7366 4.13346 19.2001 5.30046 19.2001H15.7005C16.8674 19.2001 17.9866 18.7366 18.8118 17.9114C19.637 17.0863 20.1005 15.9671 20.1005 14.8001ZM6.34252 12.6261C6.26739 12.5511 6.22521 12.4493 6.22521 12.3431C6.22521 12.2369 6.26739 12.1351 6.34252 12.0601L9.17052 9.23212L7.21452 7.27612V7.27605C7.14493 7.2045 7.10456 7.10952 7.10137 7.00972C7.09825 6.90998 7.13249 6.81259 7.19747 6.7368C7.26244 6.66102 7.35346 6.61226 7.45255 6.60008L13.7645 5.90008C13.8854 5.88654 14.0059 5.92873 14.0919 6.01473C14.1779 6.10074 14.2201 6.22125 14.2065 6.34208L13.5006 12.6481C13.4896 12.7489 13.4408 12.8416 13.364 12.9078C13.2873 12.9739 13.1883 13.0085 13.087 13.0045C12.9858 13.0004 12.8898 12.9581 12.8185 12.8861L10.8625 10.9301L8.04054 13.7581C7.96554 13.8332 7.86372 13.8754 7.75759 13.8754C7.65141 13.8754 7.54959 13.8332 7.47459 13.7581L6.34252 12.6261Z"
        fill="#7EBD5A"
      />
    </g>
    <defs>
      <clipPath id="clip0_5393_80487">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default TgsStashCash;
