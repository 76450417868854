import React from "react"; // eslint-disable-line

const TgsSpecial = ({ color, className, height }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.92 18.58L19.09 11.41C19.4625 11.0353 19.6716 10.5284 19.6716 10C19.6716 9.47163 19.4625 8.96473 19.09 8.59L10.5 0H0.5V10L9.09 18.58C9.27575 18.766 9.49632 18.9135 9.73912 19.0141C9.98192 19.1148 10.2422 19.1666 10.505 19.1666C10.7678 19.1666 11.0281 19.1148 11.2709 19.0141C11.5137 18.9135 11.7343 18.766 11.92 18.58ZM5.5 4C4.94772 4 4.5 4.44772 4.5 5C4.5 5.55228 4.94772 6 5.5 6H5.51C6.06228 6 6.51 5.55228 6.51 5C6.51 4.44772 6.06228 4 5.51 4H5.5Z"
      fill="#7EBD5A"
    />
  </svg>
);

export default TgsSpecial;
