import React from "react"; // eslint-disable-line

const TgsPhone = ({ color, className, height }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8332 13.9334V16.4334C18.8341 16.6655 18.7866 16.8952 18.6936 17.1079C18.6006 17.3205 18.4643 17.5114 18.2933 17.6683C18.1222 17.8252 17.9203 17.9447 17.7005 18.019C17.4806 18.0934 17.2477 18.121 17.0165 18.1001C14.4522 17.8214 11.989 16.9452 9.82486 15.5417C7.81139 14.2623 6.10431 12.5552 4.82486 10.5417C3.41651 8.36775 2.54007 5.89258 2.26653 3.31675C2.2457 3.0863 2.27309 2.85405 2.34695 2.63477C2.4208 2.41549 2.53951 2.21399 2.6955 2.0431C2.8515 1.87221 3.04137 1.73567 3.25302 1.64218C3.46468 1.54869 3.69348 1.5003 3.92486 1.50008H6.42486C6.82928 1.4961 7.22136 1.63931 7.528 1.90302C7.83464 2.16674 8.03493 2.53295 8.09153 2.93341C8.19705 3.73347 8.39274 4.51902 8.67486 5.27508C8.78698 5.57335 8.81125 5.89751 8.74478 6.20915C8.67832 6.52079 8.52392 6.80684 8.29986 7.03341L7.24153 8.09175C8.42783 10.178 10.1552 11.9055 12.2415 13.0917L13.2999 12.0334C13.5264 11.8094 13.8125 11.655 14.1241 11.5885C14.4358 11.522 14.7599 11.5463 15.0582 11.6584C15.8143 11.9405 16.5998 12.1362 17.3999 12.2417C17.8047 12.2989 18.1744 12.5028 18.4386 12.8147C18.7029 13.1266 18.8433 13.5247 18.8332 13.9334Z"
      fill="#7EBD5A"
      stroke="#7EBD5A"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default TgsPhone;
