import React from "react"; // eslint-disable-line

const TgsMedical = ({ color, className, height }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4993 17.6667C15.1017 17.6667 18.8327 13.9357 18.8327 9.33333C18.8327 4.73096 15.1017 1 10.4993 1C5.89698 1 2.16602 4.73096 2.16602 9.33333C2.16602 13.9357 5.89698 17.6667 10.4993 17.6667Z"
      fill="#7EBD5A"
      stroke="#7EBD5A"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 6V12.6667"
      stroke="white"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.16602 9H13.8327"
      stroke="white"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default TgsMedical;
