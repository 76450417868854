import React, { useState, useMemo } from "react";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";
import { Link, navigate } from "gatsby";
import { reduce as _reduce, isNaN as _isNaN } from "lodash";
import queryString from "query-string";

import { getReduxStore } from "../state/configureStore";
import { getBoundActions } from "../state/actions";

import Button from "../components/button/Button";
import Modal from "../components/modal/Modal";

import * as LocationStyles from "../styles/location.module.css";

import FourZeroFour from "../views/404";
import DispensaryAlert from "../components/hubspot-form/dispensary-alert/DispensaryAlert";

import FacebookIcon from "@col-care/common/elements/icons/FacebookIcon";
import InstagramIcon from "@col-care/common/elements/icons/InstagramIcon";
import TgsMedical from "@col-care/common/elements/icons/TgsMedical";
import TgsPhone from "@col-care/common/elements/icons/TgsPhone";
import TgsSpecial from "@col-care/common/elements/icons/TgsSpecial";
import TgsStashCash from "@col-care/common/elements/icons/TgsStashCash";
import TgsEmail from "@col-care/common/elements/icons/TgsEmail";
import { getFormattedStoreHours } from "@col-care/common/common";
import { GhubLink } from "@col-care/common/common";
import {
  capturePosthogLink,
  capturePosthogEvent,
} from "@col-care/common/utilities/index";
import { posthogEvents } from "@col-care/common/utilities/constants";

export const openInNewTab = (url) => {
  // 👇️ setting target to _blank with window.open
  window.open(url, "_blank");
};

export default function Location(props) {
  const state = useSelector((state) => state);
  const store = getReduxStore("init", state.router.location.pathname).store;
  const actions = getBoundActions(store.dispatch);
  const query = queryString.parse(state.router.location.search);
  const posthog = props?.posthog;

  //console.log(props);
  //console.log(state);

  const locations = state.hydrate.databagData.locations;
  const [showLocationUpdateModal, setShowLocationUpdateModal] = useState(null);

  const locationsByStoreId = useMemo(() => {
    return locations
      ? locations.reduce((a, v) => {
          a[v.content.vendor_integrations.jane_rec_id] = v;
          return a;
        }, {})
      : {};
  }, [locations]);

  if (!state.hydrate.locationData) {
    return false;
  }

  const currentStore = state.session.cartData.store.janeId
    ? locationsByStoreId[state.session.cartData.store.janeId]
    : null;

  const locationData = state.hydrate.locationData;
  const locationId = locationData?.location?.location_id;
  const layoutData = state.hydrate.layoutData;
  const cartData = state.session.cartData;
  const siteConfigData = state.hydrate.siteConfigData;

  const formattedStoreHours =
    locationData.location && locationData.location.store_hours
      ? getFormattedStoreHours(locationData.location.store_hours)
      : null;

  const whatThisDispensaryOffers = getWhatThisDispensaryOffers(
    locationData?.location,
    posthog
  );

  if (
    locationData &&
    locationData.url &&
    siteConfigData &&
    siteConfigData?.loyalty
  ) {
    return (
      <>
        <section className={LocationStyles.LocationHero}>
          <div
            className={LocationStyles.LocationImage}
            style={{
              backgroundImage: `url(${locationData.location.store_information.store_image.url})`,
            }}
          ></div>
          <div className={LocationStyles.LocationHeroInner}>
            <div className={LocationStyles.LocationText}>
              {cartData.store.id === locationId && (
                <h3 className={LocationStyles.YourLocation}>Your Location:</h3>
              )}
              <h2 className={LocationStyles.tgs_text}>The Green Solution </h2>
              <h1 className={LocationStyles.LocationHeroHeader}>
                {locationData.location.store_information.nickname_location
                  ? `${locationData.location.store_information.nickname_location}`
                  : `${locationData.location.address.city}`}
                {"  "}
              </h1>
              <div className={LocationStyles.StoreName}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={locationData.location.address.google_map.href}
                  aria-label="Directions"
                >
                  {" "}
                  {locationData.location.address.street_address_1}
                  <div className={LocationStyles.StoreAddress}></div>
                  <div
                    className={LocationStyles.StoreAddress}
                  >{`${locationData.location.address.city}, ${locationData.location.address.state} ${locationData.location.address.zip_code}`}</div>
                </a>
              </div>

              <div className={LocationStyles.hero_button_container}>
                {cartData.store.id !== locationId && (
                  <Button
                    theme="tgsButtonLocation"
                    key={1}
                    id="location-chooose"
                    label="Shop This Location"
                    onClick={(e) => {
                      if (cartData.store.id === null) {
                        cartData.store.id = locationId;
                        cartData.store.name = locationData.title;
                        cartData.store.janeId =
                          locationData.location.vendor_integrations.jane_rec_id;
                        cartData.products = [];
                        cartData.productsCount = 0;
                        actions.session.doSetCartData(cartData);
                        const link = "/shop/" + locationId;
                        navigate(link);
                        capturePosthogLink({
                          eventName: posthogEvents.STORE_NAVIGATION,
                          title: "Shop This Location",
                          to: link,
                          posthog,
                          location:
                            locationData?.location?.store_information
                              ?.nickname_location ??
                            locationData?.location?.address?.city,
                        });
                      } else {
                        capturePosthogEvent({
                          eventName: `${posthogEvents.PRODUCT_FINDER} : ${posthogEvents.LOCATION_CHANGE_MODAL_OPENED}`,
                          title: "Shop This Location",
                          posthog,
                          databag: {
                            cartData,
                            location:
                              locationData?.location?.store_information
                                ?.nickname_location ??
                              locationData?.location?.address?.city,
                          },
                        });
                        setShowLocationUpdateModal(locationData.location);
                      }
                    }}
                  />
                )}
                {cartData.store.id === locationId && (
                  <>
                    <Button
                      theme="tgsButtonLocation"
                      id="location-shop-now"
                      label="SHOP NOW"
                      onClick={(e) => {
                        if (query.redirect) {
                          navigate(query.redirect);
                        } else {
                          navigate(`/shop/${locationId}`);
                        }

                        capturePosthogLink({
                          eventName: posthogEvents.STORE_NAVIGATION,
                          title: "SHOP NOW",
                          to: query.redirect ?? `/shop/${locationId}`,
                          posthog,
                        });
                      }}
                    />
                  </>
                )}
              </div>
              <div className={LocationStyles.store_info_section}>
                <div className={LocationStyles.store_info_area}>
                  {locationData.location.store_information.telephone && (
                    <div>
                      <TgsPhone />
                      <span>
                        <a
                          className={LocationStyles.StorePhone}
                          href={
                            "tel:" +
                            locationData.location.store_information.telephone
                          }
                        >
                          {locationData.location.store_information.telephone}
                        </a>
                      </span>
                    </div>
                  )}
                  <div>
                    <TgsSpecial />
                    <span>
                      <Link
                        className={LocationStyles.anchorTag}
                        label="Specials"
                        to={`/shop/${locationData.location?.location_id}/specials`}
                      >
                        {" "}
                        View Our Specials
                      </Link>
                    </span>
                  </div>
                </div>
                <div className={LocationStyles.store_info_area}>
                  <div>
                    <TgsEmail />
                    <span>
                      <a
                        target="_blank"
                        id={"location-alerts"}
                        className={LocationStyles.anchorTag}
                        href="https://lab.alpineiq.com/join/c/1275/1276"
                      >
                        Email and SMS Alerts
                      </a>
                    </span>
                  </div>
                  <div>
                    <TgsStashCash />
                    <span>
                      <GhubLink
                        label={`Stash Cash Loyalty`}
                        posthogEvent={{
                          posthog: posthog,
                        }}
                        destination={
                          "https://cannabistcompany.com/stash-cash-app"
                        }
                      />
                    </span>
                  </div>
                </div>
                {!locationData.location.vendor_integrations
                  .jane_med_id ? null : (
                  <div className={LocationStyles.store_info_area}>
                    <div>
                      <TgsMedical />
                      <span>
                        <a
                          target="_blank"
                          className={LocationStyles.anchorTag}
                          label="Join now!"
                          href={`https://lab.alpineiq.com/joinMembers/1274?storeID=${locationData.location.aiq_rec_id}`}
                        >
                          {" "}
                          Medical Loyalty
                        </a>
                      </span>
                    </div>
                  </div>
                )}
              </div>

              {/* this isn't great */}
              <div className={LocationStyles.StoreDetails}>
                <h5>{`Hours:`}</h5>
                {formattedStoreHours?.sunday_open &&
                formattedStoreHours?.sunday_close ? (
                  <p>{`Sunday: ${formattedStoreHours?.sunday_open} - ${formattedStoreHours?.sunday_close}`}</p>
                ) : (
                  <p>{`Sunday: Closed`}</p>
                )}
                {formattedStoreHours?.monday_open &&
                formattedStoreHours?.monday_close ? (
                  <p>{`Monday: ${formattedStoreHours?.monday_open} - ${formattedStoreHours?.monday_close}`}</p>
                ) : (
                  <p>{`Monday: Closed`}</p>
                )}
                {formattedStoreHours?.tuesday_open &&
                formattedStoreHours?.tuesday_close ? (
                  <p>{`Tuesday: ${formattedStoreHours?.tuesday_open} - ${formattedStoreHours?.tuesday_close}`}</p>
                ) : (
                  <p>{`Tuesday: Closed`}</p>
                )}
                {formattedStoreHours?.wednesday_open &&
                formattedStoreHours?.wednesday_close ? (
                  <p>{`Wednesday: ${formattedStoreHours?.wednesday_open} - ${formattedStoreHours?.wednesday_close}`}</p>
                ) : (
                  <p>{`Wednesday: Closed`}</p>
                )}
                {formattedStoreHours?.thursday_open &&
                formattedStoreHours?.thursday_close ? (
                  <p>{`Thursday: ${formattedStoreHours?.thursday_open} - ${formattedStoreHours?.thursday_close}`}</p>
                ) : (
                  <p>{`Thursday: Closed`}</p>
                )}
                {formattedStoreHours?.friday_open &&
                formattedStoreHours?.friday_close ? (
                  <p>{`Friday: ${formattedStoreHours?.friday_open} - ${formattedStoreHours?.friday_close}`}</p>
                ) : (
                  <p>{`Friday: Closed`}</p>
                )}
                {formattedStoreHours?.saturday_open &&
                formattedStoreHours?.saturday_close ? (
                  <p>{`Saturday: ${formattedStoreHours?.saturday_open} - ${formattedStoreHours?.saturday_close}`}</p>
                ) : (
                  <p>{`Saturday: Closed`}</p>
                )}
              </div>
            </div>
          </div>
          <Modal
            buttonOpts={["tgssmall", "tgsTransparent"]}
            modalTitle={`Change Locations?`}
            variant={`location_update`}
            dismissModal={() => {
              setShowLocationUpdateModal(false);
              capturePosthogEvent({
                eventName: `${posthogEvents.PRODUCT_FINDER} : ${posthogEvents.LOCATION_CHANGE_MODAL_CLOSED}`,
                posthog,
                databag: { cartData },
              });
            }}
            extraClasses="modal-dialog-location-update"
            hide={!showLocationUpdateModal}
            currentStore={currentStore}
            shouldCloseOnOverlayClick={false}
            actions={actions}
            location={showLocationUpdateModal}
            query={
              typeof window === "object"
                ? window.location.search
                : state.router.location.search
            }
          />
        </section>
        {locationData.location?.store_information.about && (
          <section className={LocationStyles.aboutSection}>
            <div className={LocationStyles.aboutSectionContainer}>
              <h3>ABOUT OUR DISPENSARY</h3>
              <p>{locationData.location?.store_information.about}</p>
            </div>
          </section>
        )}
        {/*
        <section className={LocationStyles.StoreBody}>
          <div className={LocationStyles.StoreInfo}>
            <div className={LocationStyles.YourLocationMobile}>
              {cartData.store.id === locationId && (
                <h3 className={LocationStyles.YourLocation}>Your Location:</h3>
              )}
              <h1 className={LocationStyles.LocationHeroHeader}>
                {locationData.location.store_information.nickname_location
                  ? `${locationData.location.store_information.nickname_location}`
                  : `${locationData.location.address.city}`}
                {"  "}
              </h1>
              {cartData.store.id === locationId && (
                <Button
                  theme="tgsButton"
                  label="SHOP NOW"
                  id="location-shop-now"
                  onClick={(e) => {
                    navigate(`/shop/${locationId}`);
                    capturePosthogLink({
                      eventName: posthogEvents.STORE_NAVIGATION,
                      title: "SHOP NOW",
                      to: `/shop/${locationId}`,
                      posthog,
                    });
                  }}
                />
              )}
              {cartData.store.id !== locationId && (
                <Button
                  theme="tgsButton"
                  key={1}
                  id="location-chooose"
                  label="Shop This Location"
                  onClick={(e) => {
                    if (cartData.store.id === null) {
                      cartData.store.id = locationId;
                      cartData.store.name = locationData.title;
                      cartData.store.janeId =
                        locationData.location.vendor_integrations.jane_rec_id;
                      cartData.products = [];
                      cartData.productsCount = 0;
                      actions.session.doSetCartData(cartData);
                      const link = "/shop/" + locationId;
                      navigate(link);
                      capturePosthogLink({
                        eventName: posthogEvents.STORE_NAVIGATION,
                        title: "Shop This Location",
                        to: link,
                        posthog,
                        location:
                          locationData?.location?.store_information
                            ?.nickname_location ??
                          locationData?.location?.address?.city,
                      });
                    } else {
                      capturePosthogEvent({
                        eventName: `${posthogEvents.PRODUCT_FINDER} : ${posthogEvents.LOCATION_CHANGE_MODAL_OPENED}`,
                        title: "Shop This Location",
                        posthog,
                        databag: {
                          cartData,
                          location:
                            locationData?.location?.store_information
                              ?.nickname_location ??
                            locationData?.location?.address?.city,
                        },
                      });
                      setShowLocationUpdateModal(locationData.location);
                    }
                  }}
                />
              )}
            </div>

            <div className={LocationStyles.InnerInfo}>
              <div className={LocationStyles.StoreName}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={locationData.location.address.google_map.href}
                  aria-label="Directions"
                >
                  {" "}
                  {locationData.location.address.street_address_1}
                  <div className={LocationStyles.StoreAddress}></div>
                  <div
                    className={LocationStyles.StoreAddress}
                  >{`${locationData.location.address.city}, ${locationData.location.address.state} ${locationData.location.address.zip_code}`}</div>
                </a>
              </div>

              <div className={LocationStyles.StoreEmailSignup}>
                <>
                  <div style={{ margin: "15px 0" }}>
                    View Our Current Specials
                  </div>
                  <Link
                    className={LocationStyles.anchorTag}
                    label="Click Here"
                    to={`/shop/${locationData.location?.location_id}/specials`}
                    onClick={() => {
                      capturePosthogLink({
                        eventName: posthogEvents.SPECIALS_LINK_CLICKED,
                        title: "View Our Current Specials",
                        to: `/shop/${locationData.location?.location_id}/specials`,
                        posthog,
                        location:
                          locationData?.location?.store_information
                            ?.nickname_location ??
                          locationData?.location?.address?.city,
                      });
                    }}
                  >
                    Click Here
                  </Link>
                </>
              </div>

              {/* <div className={LocationStyles.EmailFormHeader}>
              Get Email Alerts
            </div> 
              <div>
                {/*<DispensaryAlert
                {...props}
                page_name={`${locationData.title} | Columbia Care`}
                page_url={`columbia.care/locations/${locationData.location.location_id}`}
                state={locationData.location.address.state}
                button_text={`Sign me up!`}
                cookies={cookies}
                /> 
                <div>Get Email & SMS Alerts</div>
                <a
                  target="_blank"
                  id={"location-alerts"}
                  className={LocationStyles.anchorTag}
                  href="https://lab.alpineiq.com/join/c/1275/1276"
                  onClick={() => {
                    capturePosthogLink({
                      eventName: posthogEvents.EXTERNAL_NAVIGATION,
                      title: "Get Email & SMS Alerts",
                      to: "https://lab.alpineiq.com/join/c/1275/1276",
                      posthog,
                      location:
                        locationData?.location?.store_information
                          ?.nickname_location ??
                        locationData?.location?.address?.city,
                    });
                  }}
                >
                  Sign me up!
                </a>
              </div>

              <div className={LocationStyles.StoreEmailSignup}>
                {!locationData.location.vendor_integrations
                  .jane_med_id ? null : (
                  <>
                    <div style={{ margin: "15px 0" }}>
                      Medical Patient Loyalty Program
                    </div>
                    <a
                      target="_blank"
                      className={LocationStyles.anchorTag}
                      label="Join now!"
                      href={`https://lab.alpineiq.com/joinMembers/1274?storeID=${locationData.location.aiq_rec_id}`}
                      onClick={() => {
                        capturePosthogLink({
                          eventName: posthogEvents.EXTERNAL_NAVIGATION,
                          title: "Medical Patient Loyalty Program",
                          to: `https://lab.alpineiq.com/joinMembers/1274?storeID=${locationData.location.aiq_rec_id}`,
                          posthog,
                          location:
                            locationData?.location?.store_information
                              ?.nickname_location ??
                            locationData?.location?.address?.city,
                        });
                      }}
                    >
                      Click Here
                    </a>
                  </>
                )}
              </div>

              <div className={LocationStyles.StoreEmailSignup}>
                {!locationData.location.vendor_integrations
                  .aiq_med_id ? null : (
                  <>
                    <div style={{ margin: "15px 0" }}>
                      Medical Patient Loyalty Program
                    </div>
                    <Button
                      theme="transparentSkinny"
                      key={2}
                      id="location-join-now"
                      label="Join now!"
                      onClick={(e) => {
                        const url = `https://lab.alpineiq.com/joinMembers/1274?storeID=${locationData.location.vendor_integrations.aiq_med_id}`;
                        capturePosthogLink({
                          eventName: posthogEvents.EXTERNAL_NAVIGATION,
                          title: "Medical Patient Loyalty Program",
                          to: `https://lab.alpineiq.com/joinMembers/1274?storeID=${locationData.location.aiq_rec_id}`,
                          posthog,
                          location:
                            locationData?.location?.store_information
                              ?.nickname_location ??
                            locationData?.location?.address?.city,
                        });
                        window.open(url, "_blank").focus();
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={LocationStyles.StoreDetails}>
            <div className={LocationStyles.InnerInfo}>
              {(() => {
                if (locationData.location?.store_information.about) {
                  return (
                    <>
                      <h5>{`About Our Dispensary:`}</h5>
                      <p>{`${locationData.location.store_information.about}`}</p>
                    </>
                  );
                }
              })()}

              {(() => {
                if (whatThisDispensaryOffers.length > 0) {
                  return (
                    <>
                      <h5>{`Check out what this dispensary offers:`}</h5>
                      <ul className={LocationStyles.StoreOffers}>
                        {whatThisDispensaryOffers}
                      </ul>
                    </>
                  );
                }
              })()}

              {(() => {
                if (
                  locationData.location.store_information
                    ?.store_information_stash_cash_participant
                ) {
                  return (
                    <>
                      <h5>{`Join Our Loyalty Program:`}</h5>
                    </>
                  );
                }
              })()}

              <div style={{ marginTop: "20px" }}>
                {(() => {
                  if (
                    locationData.location.store_information?.instagram?.href
                  ) {
                    return (
                      <a
                        style={{ padding: "0 12px" }}
                        target="_blank"
                        aria-label={
                          locationData.location.store_information.instagram.href
                        }
                        rel="noreferrer"
                        href={
                          locationData.location.store_information.instagram.href
                        }
                      >
                        <InstagramIcon height={`28px`} />
                      </a>
                    );
                  }
                })()}
                {(() => {
                  if (locationData.location.store_information?.facebook?.href) {
                    return (
                      <a
                        style={{ padding: "0 12px" }}
                        target="_blank"
                        rel="noreferrer"
                        aria-label={
                          locationData.location.store_information.facebook.href
                        }
                        href={
                          locationData.location.store_information.facebook.href
                        }
                      >
                        <FacebookIcon height={`28px`} />
                      </a>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
          <Modal
            buttonOpts={["tgssmall", "tgsTransparent"]}
            modalTitle={`Change Locations?`}
            variant={`location_update`}
            dismissModal={() => {
              setShowLocationUpdateModal(false);
              capturePosthogEvent({
                eventName: `${posthogEvents.PRODUCT_FINDER} : ${posthogEvents.LOCATION_CHANGE_MODAL_CLOSED}`,
                posthog,
                databag: { cartData },
              });
            }}
            extraClasses="modal-dialog-location-update"
            hide={!showLocationUpdateModal}
            currentStore={currentStore}
            shouldCloseOnOverlayClick={false}
            actions={actions}
            location={showLocationUpdateModal}
            query={
              typeof window === "object"
                ? window.location.search
                : state.router.location.search
            }
          />
        </section>
          */}
      </>
    );
  } else if (!layoutData) {
    return <FourZeroFour />;
  } else {
    return null;
  }
}

const getWhatThisDispensaryOffers = (location, posthog) => {
  let items = [];

  if (location) {
    if (
      location.store_information?.share_well_care_well_policy &&
      location.store_information?.share_well_care_well_policy[0]
    ) {
      items.push(
        <li key={"share_well_care_well_policy"}>
          Share Well/Care Well, a subsidy program for qualified medical
          patients.
          <br />
          <GhubLink
            label={`Learn more.`}
            destination={`https://sharewellcarewell.com/`}
            posthogEvent={{
              posthog: posthog,
            }}
          />
        </li>
      );
    }

    if (location.store_information?.cnc_card_participant) {
      items.push(
        <li key={"cnc_card_participant"}>
          Columbia National Credit, our own credit card, as a method for
          payment.
          <br />
          <GhubLink
            label={`Check it out.`}
            destination={`https://columbianationalcredit.com/`}
            posthogEvent={{
              posthog: posthog,
            }}
          />
        </li>
      );
    }

    if (location.store_information?.virtual_care_store_information?.href) {
      items.push(
        <li key={"virtual_care_store_information"}>
          Virtual.Care, a video consultation option.
          <br />
          <GhubLink
            label={`Schedule yours today.`}
            destination={
              location.store_information.virtual_care_store_information.href
            }
            posthogEvent={{
              posthog: posthog,
            }}
          />
        </li>
      );
    }

    if (location.store_information?.delivery_store_information?.href) {
      items.push(
        <li key={"delivery_store_information"}>
          Home delivery in select areas around this state.
          <br />
          <GhubLink
            label={`Learn more.`}
            destination={
              location.store_information.delivery_store_information.href
            }
            posthogEvent={{
              posthog: posthog,
            }}
          />
        </li>
      );
    }

    if (location.store_information?.pre_registration_store_information?.href) {
      items.push(
        <li key={"pre_registration_store_information"}>
          Pre-registration for new patients/caregivers.
          <br />
          <GhubLink
            label={`Start yours now.`}
            destination={
              location.store_information.pre_registration_store_information.href
            }
            posthogEvent={{
              posthog: posthog,
            }}
          />
        </li>
      );
    }

    if (location.store_information?.forage_store_information === "yes") {
      items.push(
        <li key={"forage_store_information"}>
          Forage, an interactive product discovery tool.
          <br />
          <GhubLink
            label={`Get started.`}
            destination={`https://forage.io`}
            posthogEvent={{
              posthog: posthog,
            }}
          />
        </li>
      );
    }
  }

  return items;
};
