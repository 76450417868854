import React from "react"; // eslint-disable-line

const TgsEmail = ({ color, className, height }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5013 18.6667C15.1037 18.6667 18.8346 14.9357 18.8346 10.3333C18.8346 5.73096 15.1037 2 10.5013 2C5.89893 2 2.16797 5.73096 2.16797 10.3333C2.16797 14.9357 5.89893 18.6667 10.5013 18.6667Z"
      fill="#7EBD5A"
      stroke="#7EBD5A"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 7V10.3333"
      stroke="white"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 14H10.5083"
      stroke="white"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default TgsEmail;
